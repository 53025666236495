<template>
  <div class="modal fade bd-example-modal-lg" id="reportSummarModal" tabindex="-1" role="dialog" ref="modalRef"
    aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h3 class="modal-title">Transfer Plan Summary Report</h3>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <h4 class="mb-4">
            Transfer Analysis: {{ report.source_school }} →
            {{ report.destination_school }}
            {{ report.destination_program }} Program
          </h4>

          <h5 class="section-header mt-6">Credit Summary</h5>
          <table class="table-striped">
            <tbody>
              <tr class="mb-4 p-2">
                <th>{{ report.source_school }} credits</th>
                <td>
                  {{ report.credit_summary.source_credit }} credits have been
                  earned at {{ report.source_school }}
                </td>
              </tr>
              <tr class="mb-4 p-2">
                <th>Transfer credits</th>
                <td class="text-success">
                  {{ report.credit_summary.transfer_credit }} credit hours
                  transfer
                </td>
              </tr>
              <tr class="mb-4 p-2">
                <th>{{ report.destination_school }} credits</th>
                <td>
                  {{ report.credit_summary.destination_credit }} additional
                  credits for a
                  {{ report.credit_summary.destination_requirements_credit }}
                  credit hour program
                </td>
              </tr>
            </tbody>
          </table>

          <h5 class="section-header mt-6">
            The following courses have been taken at {{ report.source_school }}:
          </h5>
          <p>Total credit hours = {{ report.credit_summary.source_credit }}</p>
          <table class="table table-striped">
            <thead>
              <tr>
                <th>Course at the {{ report.source_school }}</th>
                <th>Credits at the {{ report.source_school }}</th>
                <th>Transfer Status</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="course in report.source_data.source_data" :key="course.course">
                <td>{{ course.course }}</td>
                <td>{{ course.credit }}</td>
                <td :class="course.transferrable ? 'text-success' : 'text-danger'">
                  {{
              course.transferrable ? "Transferrable" : "Not Transferrable"
            }}
                </td>
              </tr>
            </tbody>
          </table>

          <h5 class="section-header mt-6">
            The above courses transfer to {{ report.destination_school }} as
            follows, satisfying the following degree requirements in the
            {{ report.destination_program }} program:
          </h5>
          <p>
            Total credit hours = {{ report.credit_summary.transfer_credit }}
          </p>
          <table class="table table-striped">
            <thead>
              <tr>
                <th>Course at the {{ report.source_school }}</th>
                <th>Course at the {{ report.destination_school }}</th>
                <th>Credits at the {{ report.destination_school }}</th>
                <th>
                  Satisfied Requirements at the {{ report.destination_school }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="course in report.transfer_data.transfer_data" :key="course.destination_course">
                <td>
                  {{
              Array.isArray(course.source_courses)
                ? course.source_courses.join(", ")
                : course.source_courses
            }}
                </td>
                <td>{{ course.destination_course }}</td>
                <td>{{ course.destination_credit }}</td>
                <td>{{ course.requirement }}</td>
              </tr>
            </tbody>
          </table>

          <h5 class="section-header mt-6">
            These additional courses satisfy the requirements to complete the
            {{ report.destination_program }} program at
            {{ report.destination_school }}. Please note that for many
            requirements these courses are one of many possible selections.
            Refer to the Undergraduate Catalog for a comprehensive list of
            degree requirements and course offerings.
          </h5>
          <p>
            Total credit hours = {{ report.credit_summary.destination_credit }}
          </p>
          <table class="table table-striped">
            <thead>
              <tr>
                <th>Course at the {{ report.destination_school }}</th>
                <th>Credits at the {{ report.destination_school }}</th>
                <th>
                  Satisfied Requirements at the {{ report.destination_school }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="course in report.destination_data.destination_data" :key="course.course">
                <td>{{ course.course }}</td>
                <td>{{ course.credit }}</td>
                <td>{{ course.requirement }}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="modal-footer text-center">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">
            CLOSE
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "ReportSummaryModal",
  props: {
    report: {
      type: Object,
      required: true,
    },
  },
});
</script>

<style scoped>
.modal-body {
  font-size: 13px;
  padding: 20px;
}

.modal-title {
  font-weight: bold;
}

h6 {
  font-weight: bold;
  margin-top: 20px;
  margin-bottom: 10px;
}

.section-header {
  color: #0056b3;
}

.table {
  margin-bottom: 20px;
}

.table th {
  background-color: #e7e7e7;
}

.text-success {
  color: #28a745;
}

.text-danger {
  color: #dc3545;
}

.modal-footer {
  justify-content: center;
}

.btn-secondary {
  background-color: #6c757d;
  color: white;
}
</style>