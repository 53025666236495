<template>
  <div
    class="modal fade"
    :id="course && course.id"
    tabindex="-1"
    role="dialog"
    :aria-labelledby="course && course.code"
    aria-hidden="true"
  >
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">
            Why is this course needed?
          </h5>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div
          class="modal-body"
          v-if="
            course.dest_req &&
            (course.dest_req[0].includes('Elective') ||
              [
                'Writing Courses',
                'Exploring World Cultures',
                'Understanding the Creative Arts',
                'Understanding the Past',
                'Understanding Individuals',
                'Understanding U.S. Society',
                'Humanities Social Sciences Art',
                'Understanding the Individual and Society',
                'Math Courses',
                'Science Electives',
                'Required Math Courses',
                'IE 342 or STAT 381',
              ].includes(course.dest_req[0]))
          "
        >
          For a complete list of approved courses requirement, 
          please consult the
          <a
            href="https://catalog.uic.edu/ucat/degree-programs/"
            target="_blank"
          >
            UIC Undergraduate Catalog
          </a>
        </div>

        <div class="modal-body" v-else>
          <strong>{{
            course.requirement ? course.requirement.name : course.name
          }}</strong>
          satisfies the following degree requirements :
        </div>
        <ul>
          <li v-if="course.start_req && course.start_req.length">
            <span v-for="(req, index) in course.start_req" :key="index">
              {{ req }}
              <span v-if="index < course.start_req.length - 1">, </span>
            </span>
            - {{ programName }} at {{ schoolName }}
          </li>
          <li
            v-if="
              course.dest_req &&
              course.dest_req.length &&
              isReqValid(course.dest_req)
            "
          >
            <span
              v-for="(req, index) in course.dest_req"
              :key="'dest_' + index"
            >
              {{ req }}
              <span v-if="index < course.dest_req.length - 1 && req != null"
                >,
              </span>
            </span>
            - {{ destProgramName }} at the University of Illinois Chicago
          </li>
        </ul>
        <!-- <div v-if="course.prereq && course.prereq.length">
          <ul>
            <strong> Course prerequisites / corequisites: </strong>
            <li v-for="prereq in course.prereq" :key="prereq.name">
              {{ prereq.prefix }}
              {{ prereq.num }}
              {{ prereq.name }}
            </li>
          </ul>
        </div> -->
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "Modal",

  props: {
    course: {
      type: Object,
      required: true,
    },
    programName: {
      type: String,
      required: false,
    },
    schoolName: {
      type: String,
      required: false,
    },
    destProgramName: {
      type: String,
      required: false,
    },
  },

  data() {
    return {};
  },

  computed: {},

  methods: {
    isReqValid(req: Array<any>) {
      return req.some((req) => req != null);
    },
  },
});
</script>
