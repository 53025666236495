<template>
  <link rel="stylesheet" href="https://use.fontawesome.com/releases/v5.2.0/css/all.css"
    integrity="sha384-hWVjflwFxL6sNzntih27bfxkr27PmbbK/iSvJ+a4+0owXq79v+lsFkW54bOGbiDQ" crossorigin="anonymous" />
  <div class="transfer">
    <!-- Step Indicators -->
    <section class="container step-indicator-container">
      <div class="step-indicator">
        <div class="step" :class="{ completed: step > 0 }">
          <div class="step-circle"></div>
          <p>Starting School</p>
        </div>
        <div class="line" :class="{ completed: step > 1 }"></div>
        <div class="step" :class="{ completed: step > 1 }">
          <div class="step-circle"></div>
          <p>Completed Courses</p>
        </div>
        <div class="line" :class="{ completed: step > 2 }"></div>
        <div class="step" :class="{ completed: step > 2 }">
          <div class="step-circle"></div>
          <p>Destination Program</p>
        </div>
        <div class="line" :class="{ completed: step > 3 }"></div>
        <div class="step" :class="{ completed: step > 3 }">
          <div class="step-circle"></div>
          <p>Transfer Report</p>
        </div>
      </div>
    </section>

    <div class="school-names">
      <span class="school-name" :class="{ set: startingSchool !== null }">
        <i class="fa-solid fa-school"></i>
        <span v-if="startingSchool">{{ startingSchool.name }}</span>
        <span v-else>Starting School</span>
      </span>
      <i class="fa-solid fa-arrow-right"></i>
      <span class="school-name" :class="{ set: destinationSchool !== null }">
        <i class="fa-solid fa-school"></i>
        <span v-if="destinationSchool">{{ destinationSchool.name }}</span>
        <span v-else>Destination School</span>
      </span>
    </div>

    <section class="container form">
      <template v-if="loading || error">
        <div class="text-center" v-if="loading">
          <h4>{{ loadingMsg }}</h4>
          <h3 class="loader"></h3>
        </div>
        <div class="text-center error-message" v-if="error">
          <h4>{{ errorMsg }}</h4>
          <div class="actions">
            <button class="ube-btn" @click="
          step -= 1;
        error = false;
        ">
              Back
            </button>
          </div>
        </div>
      </template>
      <template v-else>
        <template v-if="step === 1">
          <h3>1. Select your community college:</h3>
          <search-select class="school-selection-search" :items="schools" :labelKey="'name'" :placeholder="startingSchool ? startingSchool.name : `Search Schools`
          " @item-selected="selectStartingSchool" />
          <div class="actions">
            <button v-if="startingSchool" class="ube-btn" @click="step += 1">
              Next
            </button>
          </div>
        </template>

        <template v-if="step === 2">
          <h3>2. Select courses completed at {{ startingSchool.name }}:</h3>
          <search-select class="course-selection" :items="startingSchoolCourses" :labelKey="'full_name'"
            placeholder="Completed Course" @item-selected="addCourse" />
          <div class="course-equivalent-list">
            <div class="course-equivalent" v-for="(course, i) in takenCourses" :key="course.id">
              <span class="course-name-span">
                {{ course.name }}
              </span>
              <a class="remove" @click="removeCourse(i)">
                <i class="fa-solid fa-xmark"></i>
                Remove
              </a>
            </div>
          </div>
          <div class="actions">
            <button class="ube-btn" @click="step -= 1">Back</button>
            <button class="ube-btn" @click="step += 1">Next</button>
          </div>
        </template>

        <template v-if="step === 3">
          <h3>4. Select destination program at UIC:</h3>
          <search-select class="program-selection-search" :items="programs" :labelKey="'name'" :placeholder="destinationProgram
          ? destinationProgram.name
          : `Search Degree Programs`
          " @item-selected="selectDegreeProgram" />
          <div style="text-align: center; font-size: larger; font-weight: bolder" v-if="destinationProgram">
            Selected Program: {{ destinationProgram.name }}
          </div>
          <div class="actions">
            <button class="ube-btn" @click="step -= 1">Back</button>
            <button v-if="destinationProgram" class="ube-btn generate-report" @click="generateTransferReport">
              Generate Transfer Report
            </button>
          </div>
        </template>

        <template v-if="step === 4">
          <div class="container-fluid transfer two-to-four-year-transfer-container">
            <div class="row two-to-four-year-report-container">
              <div class="col-md-12 p-0">
                <button data-toggle="modal" data-target="#reportSummarModal" class="btn btn-primary mb-4" type="button">
                  Analyze transfer plan
                </button>
              </div>
              <div class="col-md-12 dp-visualization-container">
                <DegreePlanVisualization v-if="transferPlan.destDegreeplan"
                  :planTermData="transferPlan.destDegreeplan" />
              </div>
              <!-- <div class="col-md-12 dp-visualization-container"> -->
              <!-- <curriculum :curriculum='transferReport.curriculum'></curriculum> -->
              <!-- </div> -->
              <ReportSummaryModal :report="transferPlanAnalysis"></ReportSummaryModal>
              <div class="actions">
                <button class="ube-btn mt-3" @click="step -= 1">Back</button>
              </div>
            </div>
          </div>
        </template>
      </template>
    </section>
  </div>
</template>

<script lang="ts">
import axios from "axios";
import type School from "@/models/school";
import { defineComponent } from "vue";
import SearchSelect from "@/components/SearchSelect.vue";
import type Course from "@/models/course";
import type Program from "@/models/program";
import type RawCourse from "@/models/rawcourse";
import httpClient from "@/common/httpClient";
// import { disableAutoUnmount } from "@vue/test-utils";
import ReportTable from "@/components/ReportTable.vue";
import TakenCoursesTable from "@/components/TakenCoursesTable.vue";
import ReportSummaryModal from "@/components/ReportSummaryModal.vue";
// @ts-ignore
import { DegreePlanVisualization } from '@unm-idi/vue-degree-plan/vue-degree-plan.js';
import '@unm-idi/vue-degree-plan/vue-degree-plan.css'
// @ts-ignore
// import { Curriculum, buildCurriculum } from '@damoursystems/curricular-visualization'

export default defineComponent({
  name: "TransferView",

  data() {
    return {

      /** List of course equivalents */
      courseEquivalents: [] as Array<Course>,

      /** The destination school */
      destinationSchool: null as unknown as School,

      /** Courses offered by the destination school */
      destinationSchoolCourses: [] as Array<Course>,

      /** Program selected at destination school */
      destinationProgram: null as unknown as Program,
      /** List of courses that are needed */
      // neededCourses: [] as Array<Course>,

      /** List of programs that may be completed at destination school */
      programs: [] as Array<Program>,

      /** List of schools for selection */
      schools: [] as Array<School>,

      /** Flag to show the transfer report */
      showingTransferReport: false,

      /** The starting school */
      startingSchool: null as unknown as School,

      /** Courses offered by the starting school */
      startingSchoolCourses: [] as Array<Course>,

      /** The step of the form */
      step: 1,

      loading: false,
      loadingMsg: "",
      error: false,
      errorMsg: "",

      /** The courses the user took at starting institution */
      takenCourses: [] as Array<Course>,
      transferPlan: {
        takenCourses: [],
        destDegreeplan: {},
      },
      transferPlanAnalysis: " " as Object,
      /** The transfer report */
      transferReport: {
        curriculum: {},
        options: {
          // Enable edit mode for curriculum (default: false)
          edit: false,
          // Hide term header / footer information (default: false)
          hideTerms: false,
          // Hide curriculum item grid (default: false)
          hideGrid: false,
          // Hide prereq / coreq / strict-coreq interaction (default: false)
          hideRequisiteAssociations: false,
          // Hide blocking factor interaction (default: false)
          hideBlocking: true,
          // Hide delaying factor interaction (default: false)
          hideDelaying: true,
          // Hide legend (default: false)
          hideLegend: true,
        },
      },
    };
  },

  mounted() {
    // Get schools
    this.getSchools();
    let UIC_School = {
      id: 4036,
      name: "University of Illinois Chicago",
      slug: "university-of-arizona",
      disable: false,
    };
    this.getdestPrograms(UIC_School);
  },

  methods: {
    addCourse(course: Course) {
      this.takenCourses.push(course);
    },

    /**
     * Determine the equivalent courses for all of the user's takenCourses
     */
    async evaluateCourses() {
      // send takenCourses to be looked up by code and full_number
      // use upper case university name with spaces instead of -
      var params = new URLSearchParams();
      for (let course in this.takenCourses) {
        params.append("taken_courses[]", this.takenCourses[course].code);
        console.log(this.takenCourses[course].code);
      }
      params.append(
        "from_institution",
        this.startingSchool.name.toUpperCase().replace("-", " ")
      );
      params.append(
        "to_institution",
        this.destinationSchool.name.toUpperCase().replace("-", " ")
      );
      await axios
        .get(`${httpClient.defaults.baseURL}/transfer/${"equivalencies"}`, {
          params: params,
        })
        .then((response) => {
          const equivalencies = response.data;
          console.log(equivalencies);
          this.courseEquivalents = equivalencies;
          console.log(this.courseEquivalents);
          console.log("Got course equivalencies");
        });
    },
    createTermObjects(courseList: Array<any>, startTermFrom: number) {
      // Determine the highest term number
      let maxTerm = Math.max.apply(
        Math,
        courseList.map((course) => course.term)
      );

      let minTerm = Math.min.apply(
        Math,
        courseList.map((course) => course.term)
      );

      // Create the term array
      let terms = [] as Array<any>;
      for (let i = minTerm; i <= maxTerm; i++) {
        let term = {
          id: i,
          name: "Term " + (i + startTermFrom),
          credits: 0,
          position: 0,
          plan_items: [],
        };
        terms.push(term);
      }

      courseList.forEach(course => {
        let termIndex = course.term - minTerm;
        let term = terms[termIndex];

        let planItem = {
          id: course.id,
          name: course.full_name,
          credits: parseInt(course.minimum_credits),
          custom: false,
          position: term.plan_items.length,
          plan_item_spec: null,
          destination_requirement: course.dest_req[0],
          requirement: {
            id: course.name,
            name: course.name,
            parent_id: termIndex,
            type: "CourseRequirement",
            credits: parseInt(course.minimum_credits),
            advanced: false,
            attempts: 1,
            minimum_grade: course.minimum_grade,
            course: {
              id: course.id,
              code: course.code,
              full_number: course.code,
              name: course.name,
              full_name: course.full_name,
              minimum_credits: parseInt(course.minimum_credits),
              maximum_credits: parseInt(course.maximum_credits),
              description: course.dest_req + " - " + this.destinationProgram.name + ' at the ' + this.destinationSchool.name,
              created_at: new Date().toISOString(),
              updated_at: new Date().toISOString(),
              academic_period: {
                id: term.id,
                term: `Term ${term.id}`,
                year: new Date().getFullYear(),
                code: `${new Date().getFullYear()}.${term.id}`,
                name: `Term ${term.id} ${new Date().getFullYear()}`,
                academic_year: `${new Date().getFullYear()}-${new Date().getFullYear() + 1}`
              },
              prereq: course.prereq,
              coreq: course.coreq,
              canonical_course: null
            }
          }
        };

        term.plan_items.push(planItem);
        term.credits += planItem.credits;
      });

      return terms;
    },

    /**
     * Generate a transfer report
     */
    async generateTransferReport() {
      // get the program requirements
      this.loading = true;
      this.loadingMsg = "Generating transfer Plan...";
      this.error = false;

      const data = {
        // 'x-api-key': ENV["CA_API_KEY"]
        source_school: this.startingSchool,
        dest_program: this.destinationProgram,
        taken_courses: this.takenCourses,
      };

      const config = {
        headers: {
          "Content-Type": "application/json",
          "x-api-key": "2D9QpLXVXo2fY6E9B1dTb8HddPygm7Ga84NsQ1rX",
        },
      };

      await axios
        .post(
          "https://api.curricularanalytics.com/optimize/completion_plan",
          // "http://localhost:8080/completion_plan",

          data,
          config
        )
        .then((response) => {
          // this.transferReport = buildCurriculum(response.data.curriculum, { Item: CustomItem })
          const responseObj = response.data;
          this.transferPlan.destDegreeplan = responseObj.destination_degreeplan ? this.createTermObjects(responseObj.destination_degreeplan, 0) : {};
          this.transferPlan.takenCourses = responseObj.taken_courses ? responseObj.taken_courses : [];
          this.transferPlanAnalysis = responseObj.transfer_plan_report ? responseObj.transfer_plan_report : "";
          // this.transferReport.curriculum = buildCurriculum(responseObj.destination_degreeplan)
        })
        .catch((error) => {
          console.log(error);
          this.transferReport = error;
          this.error = true;
          this.errorMsg = error.message;
        });
      this.loading = false;
      this.step += 1;
    },

    /**
     * Fetch all academic programs at the given school
     */
    async getPrograms(school: School): Promise<Program[]> {
      const programs = [] as Array<Program>;

      await axios
        .get(
          `${httpClient.defaults.baseURL}/universities/${school.id}/academic_periods/112/university_layouts`
        )
        .then((response) => {
          const filteredPrograms = response.data.filter((program: any) => {
            // Filter entities that are not a program and that don't have periodDegreeJoin
            if (
              program.type === "program" &&
              program.period_degree_joins.length > 0
            ) {
              // Filter out peridDegreeJoin that are not a MAJOR
              program.period_degree_joins.filter((degreeJoin: any) => {
                if (degreeJoin.code === "MAJOR") {
                  return true;
                } else {
                  return false;
                }
              });
              return true;
            } else {
              return false;
            }
          });
          filteredPrograms.forEach((element: any) => {
            const program = {
              name: element.program.name,
              id: element.program.id,
              code: element.program.code,
              // Code should equal "MAJOR"
              periodDegreeJoins: element.period_degree_joins,
              plan_period_join: null as unknown as number,
              plan_terms: [],
              requirements: [],
              disable: false,
            };
            programs.push(program);
          });
        });
      console.log(programs);
      return programs;
    },

    /**
     * Fetch all schools
     */
    getSchools() {
      this.schools.push(
        // TODO: Get these IDs
        {
          id: 2,
          name: "City Colleges of Chicago",
          slug: "ccc",
          disable: false,
        }
      );
    },

    /**
     * Fetch all courses for the given school
     */
    async getSchoolCourses(school: School): Promise<Course[]> {
      console.log(httpClient);
      console.log(httpClient.defaults.baseURL);
      const courses = [] as Array<Course>;
      await axios
        .get(
          `${httpClient.defaults.baseURL}/universities/${school.id}/academic_periods/108/courses`
        )
        .then((response) => {
          courses.push(...response.data);
        });

      return courses;
    },

    loadVisualization() {
      const curriculumFrame = document.getElementById("curriculum");
      const vizData = JSON.parse(JSON.stringify(this.transferReport));
      if (curriculumFrame) {
        const isIFrame = (
          input: HTMLElement | null
        ): input is HTMLIFrameElement =>
          input !== null && input.tagName === "IFRAME";
        if (isIFrame(curriculumFrame) && curriculumFrame.contentWindow) {
          console.log(`pasting data ${vizData}`);
          curriculumFrame.contentWindow.postMessage(vizData, "*");
        }
      }
    },

    /**
     * Removes the coures equivalent at the index
     */
    removeCourse(ind: number) {
      console.log(ind);
      this.takenCourses.splice(ind, 1);
    },

    /**
     * Handles the selection of the starting school
     */
    async loadAndConvertCourseData(school: School): Promise<Course[]> {
      try {
        const response = await axios.get(`/data/${school.slug}_catalog.json`);
        const jsonData = response.data;
        const catalog = jsonData.catalog as Record<string, RawCourse>;

        const courses = Object.entries(catalog).map(
          ([id, course]: [string, RawCourse]): Course => ({
            id: parseInt(id),
            code: `${course.prefix} ${course.num}`.trim(),
            full_number: `${course.prefix} ${course.num}`.trim(),
            name: `${course.prefix} ${course.num}`.trim(),
            full_name: `${course.prefix} ${course.num} - ${course.name}`.trim(),
            minimum_credits: course.credit_hours,
            maximum_credits: course.credit_hours,
          })
        );
        return courses;
      } catch (error) {
        console.error("Error loading or parsing the course data:", error);
        return [];
      }
    },
    async selectStartingSchool(school: School) {
      this.loading = true;
      this.takenCourses = [];
      this.loadingMsg = `Getting Courses for the ${school.name}...`;
      this.startingSchool = school;
      this.startingSchoolCourses = await this.loadAndConvertCourseData(school);
      this.step = 2;
      this.loading = false;
    },
    /**
     * Handles the selection of the destination school
     */
    async getdestPrograms(school: School) {
      this.destinationSchool = school;
      //this.destinationSchoolCourses = await this.getSchoolCourses(school);
      this.programs
        // = await this.getPrograms(school);
        .push({
          name: "Computer Science",
          id: 1464,
          code: "COSCBS",
          periodDegreeJoins: [
            {
              id: 1675,
              total_credit_hours: 120,
              degree_type: {
                id: 1,
                name: "Major",
                code: "MAJOR",
              },
            },
          ],
          plan_period_join: 1675,
          plan_terms: [],
          requirements: [],
          disable: false,
        });
    },

    async selectDegreeProgram(program: Program) {
      // http://curriculum.damoursystems.com/api/universities/eastern-kentucky-university/period_degree_joins/1746/requirements
      // await axios
      //   .get(`/universities/${this.destinationSchool.slug}/period_degree_joins/${program.periodDegreeJoins[0].id}/requirements`)
      //   .then(response => {
      //     const requirements = response.data
      //     program.requirements = requirements
      //   })
      this.destinationProgram = program;
    },
  },

  components: {
    SearchSelect,
    ReportTable,
    TakenCoursesTable,
    ReportSummaryModal,
    DegreePlanVisualization
    // Curriculum
  },
});
</script>
